
.carousel-indicators {
    justify-content: center;
    pointer-events: none;
    gap: .5rem;
    margin-left:auto;
    margin-right: auto;
    width: 100%;
    max-width: 1800px;
    padding:0 15px;
    align-items: flex-end;
    [data-bs-target] {
        width: 60px;
        height: 5px;
        pointer-events:all;
        transition: transform .15s ease, opacity .15s ease, height .15s ease;
        &:hover{
            transform: scale(1.1);
            opacity: 1;
        }
        &.active{
            height: 14px;
        }
    }
    @include media-breakpoint-down(md) {
        justify-content: center;
    }
}
.carousel-inner{
    clip-path: inset(0);
    &.img-icon:before{
        top: 30px;
    }
}
.carousel-caption{
    padding-top: 3rem;
    padding-bottom: 0;
    top:0;
    left:0;
    right:0;
    hyphens: none;
    .h1{
        font-size: clamp(24px, 5vw, 100px);
    }
    .link-with-arrow{
        &::after{
            margin-top: 0;
            transition: filter .3s ease;;
        }
    }
}
#splash-wrap{
    .container-xxl h1{
        font-size: clamp(28px, 5.25vw, 70px);
    }
}

.carousel-item{
    background-color: rgba(77, 77, 77, .7);
    pointer-events: none;
    img{
        position: fixed;
        mix-blend-mode: multiply;
        pointer-events: none;
    }
    .link-with-arrow{
        &:hover{
            border-color: $blue;
            &::after{
                content: url("../assets/arrow-blue.svg")!important;
            }
        }
    }
}   
