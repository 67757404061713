@import "variables";


.clip-img{
    clip-path: inset(0);
}
#splash-wrap{
    min-height: clamp(375px, 50vw, 650px);
}
   
 
.clip-img img{
    max-height: clamp(375px,50vw,650px);
}


.t-home{
    #splash-wrap{
        min-height:100vh; 
        height:max(calc(10vw + 500px), 100vh); 
        position: relative;
     }
     .clip-img img{
        max-height: none;
    }
}
.t-home, .t-contact{
    #splash-wrap .summary{
        display: none;
    }
}

.t-contact{
    .contact-details{
        a svg{
            fill:$green;
        }
        .contact-icon{
            svg{
                width: 30px;
                min-width: 30px;
            }
        }
    }
    #offices{
        background-color: #F6F6F6;
        .office-card svg{
            height: 18px;
            width: 18px;
            min-width: 18px;
            object-fit: contain;
        }
    
    }
    .contact-form{
        label.float{
            color:#707070;
        }
        input, textarea{
            &.form-control{
                border:none;
                border-bottom: 1px solid $green;
                color: $blue;
            }  
        }
        .recaptcha-notice{
            color:$grey;
            a{
                color:$grey;
            }
        }
    }
}
.t-news{
    .pager{
        background-color: #F2F2F2;
        ul{
            margin: 0;
            font-weight: bold;
        }
    }
    a.item{
        &:hover{
            .start-date, .item-description, .item-title{
                color:$blue!important;
            }
        }
        .start-date{
            font-size: 16px;
            margin-top: 1.5rem;
            margin-bottom: .5rem;
            color:$green;
        }
        .start-date, .item-description, .item-title{
            transition: color .3s ease;
        }
        picture{
     
            &::after{
                position: absolute;
                inset:0;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: rgba(38, 69, 82, .8);
                content: "VIEW  "  url("../assets/next-green.svg");
                opacity: 0;
                transition: opacity .3s ease;
                font-size: 16px;
                font-weight: bold;
            }
        }
        &:hover{
            picture::after{
                opacity: 1;
            }
        }

    }
}
.t-experience{
    a.item{
        &:hover{
            .item-title{
                color:$green!important;
            }
        }
    }
}
.new-content-layout{
    overflow: hidden;
    .summary-content{
        position: relative;

        &::after{
            content:"";
            position: absolute;
            background-color: $dirty-white;
            right:0;
            top:0;
            bottom:0;
            width: 50vw;
            z-index: -1;
            @include media-breakpoint-down(lg) {
                left: -25vw!important;
                width: 150vw;
            }
        }
        &.reverse{
            &::after{
                left:0;
            }
        }
    }
}