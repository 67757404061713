@import "variables";

input.form-control , select.form-control {
    border:none;
    border-bottom: 1px solid $white;
    height: 43px;
    font-weight: bold;
    outline:none!important;
    &:focus{
        border-bottom: 1px solid $white;
    }
}

.js-float-wrap{
    margin:0;
}
label.float {
    font-size: 18px;
    font-weight: 700;
    color: $dirty-white;
    text-transform: none;
    padding-left:0;
    font-family: "proxima-nova", sans-serif;
}

.js-float-wrap.focused label.float, .js-float-wrap.populated label.float{
    padding-top: 0;
}
select{
    background-color: #F2F2F2;
    box-shadow: none;
    border: none;
    border-bottom: 1px solid $green;
    color: $blue;
}