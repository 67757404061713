
@import "variables";
@import "../../node_modules/bootstrap/scss/bootstrap.scss";
@import "../../node_modules/glightbox/dist/css/glightbox.css";
@import '@splidejs/splide/css';

// Our own scss below
@import "header";
@import "splash";
@import "footer";
@import "blocks";
@import "page";
@import "forms";

// ckeditor textarea style
.cke_textarea_inline.cke_editable{
    background-color: #dfdfdf;
}
// end of ckeditor textarea style

// Font import here
@font-face {
    font-family: 'Mazzard H';
    src: url('../font/MazzardH-Regular.woff2') format('woff2'), /* Regular weight */
    url('../font/MazzardH-Bold.woff2') format('woff2'), /* Bold weight */
    url('../font/MazzardH-SemiBold.woff2') format('woff2'), /* Bold weight */
    url('../font/MazzardH-Italic.woff2') format('woff2') /* Italic weight */;
    font-weight: normal, bold, 600, italic,; /* Define font weights */
    font-style: normal, italic; /* Define font styles */
  }
.container-xxl {
    @include media-breakpoint-down(lg) {
        --bs-gutter-x: 4rem;
    }
    @include media-breakpoint-down(sm) {
        --bs-gutter-x: 2rem;
    }
    
}

#content-wrap.snap-parent{
    height: 100vh;
    overflow-y: scroll;
    scroll-snap-type: y proximity;
    
    .block-wrapper > div, #splash-wrap, footer{
        scroll-snap-align: start;
    }

}

.recaptcha-notice{
    color: $white;
    a{
        color: $white;
        text-decoration:  underline;
    }
}
.with-underline{
    border: none;
    border-bottom: 8px solid #A8C7B1;
    padding-bottom: 10px;
}
.link-with-arrow{
    @extend .with-underline;
    font-weight: 600;
    font-size: 1.2rem;
    position: relative;
    transition: all .3s ease;
    &::after{
        content: url("../assets/arrow.svg");
        height: 12px;
        width: 12px;
        margin-left: 1rem;
        margin-top: 5px;
        display: inline-block;
        position: absolute;
    }
}
.proxima-nova{
    font-family: "proxima-nova", sans-serif;
}

a.text-white{
    transition: color .3s ease;
    
    &:hover{
        color:$blue!important;
    }
}
.bg-dark-green .text-blue.link-with-arrow, .bg-green .text-blue.link-with-arrow, .bg-blue .text-green.link-with-arrow{
    &:hover{
        color: $white!important;
        border-color: $white;
        &::after{
            content: url("../assets/arrow-white.svg")!important;
        }
    }
}

.text-blue.link-with-arrow{
    border-color: $blue;
    &::after{
        content: url("../assets/arrow-blue.svg");
    }
    &:hover{
        color: $green!important;
        border-color: $green;
        &::after{
            content: url("../assets/arrow-green.svg");
        }
    }
}
.text-green.link-with-arrow{
    &::after{
        content: url("../assets/arrow-green.svg");
    }
    &:hover{
        color: $blue!important;
        border-color: $blue;
        &::after{
            content: url("../assets/arrow-blue.svg");
        }
    }
}
.text-white.link-with-arrow{
    &:hover{
        color: $green!important;
        &::after{
            content: url("../assets/arrow-green.svg");
        }
    }
}
.text-dirty-white.link-with-arrow{
    border-color: $dirty-white;
    &::after{
        content: url("../assets/arrow-dirty-white.svg");
    }
    &:hover{
        color: $green!important;
        border-color: $green;
        &::after{
            content: url("../assets/arrow-green.svg");
        }
    }
}

blockquote{
    font-size: 30px;
    margin-left: 3rem;
    border-left: 1px solid $green;
    padding-left:3rem;
    font-weight: bold;
    color:$green;
}
ul.tick-marks{
    list-style: none;
    padding-left: 2rem;
    li{
        position: relative;
      
    }
    li:before {
        content: '✓';
        color:$green;
        padding-right: 1rem;
        position: absolute;
        right: 100%;
    }
}
// end of custom ckeditor editor style

// site style
.site-links{
    svg{
        height: 18px;
        width: 18px;
    }
}

ul.three-column{
    list-style: none;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap:2.5rem;
    margin:0;
    padding:0;
    & > li{
        display: block;
    }
    @include media-breakpoint-down(lg) {
        grid-template-columns: repeat(2, 1fr);
    }
    @include media-breakpoint-down(sm) {
        grid-template-columns: 1fr;
    }
}
//For picture tag
.img-tint{
    display: block;
    position: relative;
    &::after{
        content:"";
        inset:0;
        position: absolute;
        background-color: #919191;
        opacity: .8;
        mix-blend-mode: multiply;
    }
}
.img-tint-darker{
    @extend .img-tint;
    &::after{
        background-color: #4D4D4D;
    }
}
.tint-hover{
    transition: background-color .3s ease-in-out;
    &:hover{
        background-color: rgba(38, 69, 82, .8);
    }
}
.img-icon{
    &::before{
        pointer-events: none;
        content: "";
        position: absolute;
        inset:0;
        background-size: 20% auto;
        z-index: 100;
        background-image: url("../assets/image_icon.svg");
        background-position: top right;
        background-repeat: no-repeat;
    }
}
.img-icon-white{
    @extend .img-icon;
    &::before{
        background-size: 15% auto;
        background-image: url("../assets/image_icon_white.svg");
        mix-blend-mode: overlay;
        z-index: 0;
    }
    
}

.flex-equal-size{
    & > *{
        flex: 1 1 0px;
    }
}
.collapse-menu .btn::after{
    display: none;
}
.opening-hours{
    display: flex;
    flex-direction: column;
}

.site-links{
    a svg{
        width: 25px;
        height: 25px;
        fill: $green;
        object-fit: contain;
        transition: fill .3s ease-in-out; 
        &:hover{
            fill:$blue;
        }
    }
    a:hover{
        color:$blue;
    }
}

.goverlay {
    background-color: $blue;
    opacity: .95;
}
.gslide-image img{
    height: 97vh;
}
.gslide-desc{
    font-family: $font-primary!important;
}
.glightbox-mobile .goverlay {
    background-color: $blue;
    opacity: 1;
}
.glightbox-clean .gslide-description{
    max-width: 600px;
    min-height: 50vh;
    @include media-breakpoint-down(xl) {
        max-width: 500px;
    }
    @include media-breakpoint-down(md) {
        max-width: 400px;
    }
}
.gslide-media.gslide-image{
    background-color: $blue;
}

.share-bar{
    font-size: 14px;
    border-top:1px solid $green;
    margin-top: 3rem;
    svg{
        height: 18px;
        width: 18px;
        max-width: 18px;
        object-fit: contain;
        fill:$green;
    }
}

.reveal{
    position: relative;
    transform: translateY(100px);
    opacity: 0;
    transition: 1.5s opacity ease, 2.2s transform ease;
    &.active{
        transform: translateY(0);
        opacity: 1;
    }
}
.download-list{
    list-style: none;
    padding: 0;
    li{
        position: relative;
        padding-left: 2rem;

        &::before{
            content:"";
            position: absolute;
            left: 0;
            padding-right: 1rem;
            width: 17px;
            height: 26px;
            background: url('../assets/download-icon.svg');
            background-size: contain;
            background-repeat: no-repeat;
        }
    }
}
.download-hover{
    list-style: none;
    padding: 1.5rem;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    transition: background-color .3s ease, color .3s ease;
    grid-gap:1rem;
    
    img{
        transition: transform .3s ease;
        width: 100%!important;
        margin-bottom: -30%;
        height: auto!important
    }
    &:hover{
        background-color: $primary;
        img{
            transform: scale(1.05);
        }
        color: $white !important;
        a{
            color: $white !important;
        }
    }
    @include media-breakpoint-down(xl) {
        grid-template-columns: 1fr;
        padding:0;
        padding-bottom: 2rem;
        margin: 0;
        a{
            display: flex;
            justify-content: center;
            img{
                margin-bottom: -5%;
                width: 75%!important;
            }
        }
    }
    
    @include media-breakpoint-down(lg) {
        grid-template-columns: 1fr 1fr;
        text-align: center;
        img{ 
            padding-bottom: 2rem;
        }
    }
      
    @include media-breakpoint-down(md) {
        grid-template-columns: 1fr!important;
       
    }

}