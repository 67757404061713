@import "variables";

footer{
    background-color: $blue;
    .footer-logo{
        width: clamp(150px, 5vw, 220px);
    }
    
    .footer-menu{
        ul{
            list-style: none;
            font-family: "poppins", sans-serif;
            font-size: 14px;
        }
        .menu-left a{
            &:hover{
                color:$green!important;
                svg{
                    fill:$green;
                }
            }
        }
        .menu-right a{
            &:hover{
                color:$green!important;
            }
        }
        .site-links a svg{
            fill: $grey;
            height: 20px;
        }
    }
 
    #footer-content{
        line-height: 1;
        padding-right: 2rem;
        @include media-breakpoint-down(md) {
            text-align: center;
            padding-right: 0;
        }
    }
    .site-links a{
        display: flex;
        align-items: center;
    }


}