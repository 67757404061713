// For complete list of CSS variables (bootstrap 5.3)
// https://getbootstrap.com/docs/5.3/customize/css-variables/

// For complete list of SCSS variables 
// @import "../../node_modules/bootstrap/scss/_variables.scss";

$enable-negative-margins: false; 

// Theme colors
$blue: #264552;
$green:#A8C7B1;
$dark-green:#8EA695;
$dark: #4D4D4D;
$white: #ffffff;
$dirty-white: #F2F2F2;
$grey: #919191;
$primary: $blue;
$secondary: $green;

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1200px,
  xxl: 1800px
);
$container-padding-x:6rem;

$theme-colors: (
  "primary":    $primary,
  "secondary":  $secondary,
  "green":      $green,
  "dark-green": $dark-green,
  "blue":       $blue,
  "light":      $white,
  "white":      $white,
  "dark":       $dark,
  "dirty-white":$dirty-white,
  "grey": $grey
);

// For other theme color
// refer to https://getbootstrap.com/docs/5.3/customize/sass/ for more details
// $success:       $green ;
// $info:          $cyan ;
// $warning:       $yellow ;
// $danger:        $red lt;
// $light:         $gray-100 !default;
// $dark:          $gray-900 !default;

// Font Color
$primary-text-emphasis: $primary;
$body-emphasis-color: $primary;
$body-color: $dark;

// Font Family

$font-primary:"proxima-nova", sans-serif;

$font-family-base: "proxima-nova", sans-serif;
$headings-font-family: 'Mazzard H', sans-serif;


// Font Sizes and Weight
$font-size-root: 20px;
$font-size-base: 1rem;
$headings-font-weight: 600;
// $body-font-weight: 700;
// $font-weight-base: 700;
// For converting px to rem
// https://nekocalc.com/px-to-rem-converter

$h1-font-size: 3.5rem; //70px
$h2-font-size: 1.8rem;  //36px
$h3-font-size: 1.4rem;  //30px
$h4-font-size: 1.1rem;  //22px
$h5-font-size: 1rem;  //20px
$h6-font-size: .8rem;  //16px

// Lead and Small
$lead-font-size: 1.1;   //18px
$lead-font-weight: 700; //medium
$small-font-size: 16px;

// Links
$link-color: $blue;
$link-decoration: none;
$link-hover-color: $green;

// Navbar
$navbar-padding-y:0;
$nav-link-padding-y:1.5rem;

$nav-link-font-size:16px;
$nav-link-hover-color: $white;
$navbar-light-hover-color: $white;
$navbar-light-icon-color: $white;
$navbar-light-color:$white;
$navbar-light-active-color:$white;
$navbar-nav-link-padding-x:1rem;
$navbar-toggler-border-radius:5px;

// Dropdown
$dropdown-color: #fff;
$dropdown-bg: $green;
$dropdown-border-radius: 0;
$dropdown-spacer: -1px;
$dropdown-border-width:0;
$dropdown-link-active-bg: $green;
$dropdown-min-width: 100%;

// Button
$btn-border-radius: 0;
$btn-padding-x:0;
$btn-font-weight: 700;
$btn-color: #fff;

// Form
$focus-ring-width: 1px;
$input-border-radius:  0px;
$input-bg: transparent;
$input-focus-border-color: transparent;
$input-focus-color:  $blue;
$input-color: $blue;

//Pagination
$pagination-color: $green;
$pagination-active-color: $blue;
$pagination-active-bg: transparent;  
$pagination-active-border-color: transparent;
$pagination-bg: transparent;
$pagination-border-width: 0;
$pagination-hover-color: $blue;
$pagination-hover-bg: transparent;
$pagination-disabled-bg: transparent;
$focus-ring-width: 0;
$focus-ring-color: transparent;

$line-height-base: 1.2;
$spacer: 1rem;
$spacers: (
  0: 0,
  1: $spacer * .25,
  2: $spacer * .5,
  3: $spacer,
  4: $spacer * 1.5,
  5: $spacer * 3,
  6: $spacer * 6,
  7: $spacer * 12,
);