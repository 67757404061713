.arrow-show-more{
    position: relative;
    &::after{
        content:'';
        background-image: url("../assets/next-green.svg");
        background-repeat: no-repeat;
        background-size: contain;
        display: block;
        height: 20px;
        width: 20px;
    }
    &:hover::after{
        background-image: url("../assets/next.svg");
    }
}
.block-subscription{
    .img-icon-white:before{
        opacity: .5;
    }
    input{
        color: $white!important;
    }
}
.block-service{
    .cta-title::after{
        content: url('../assets/next.svg');
        height: 100%;
        width: 12px;
        display: flex;
        align-items: center;
        margin-left:1rem;
    }
    .item-summary{
        opacity: 0;
        transition: opacity 1s ease,max-height .5s ease;
        max-height: 33%;
    }
    picture{
        overflow: hidden;
        img{
            transition: transform 1s ease;
        }
    }
    a{
        &:hover{
            picture img{
                transform: scale(1.2);
            }
            .item-summary{
                opacity: 1;
                max-height: 100%;
            }
        }
    }
}
.block-clients{
    background-color: #F2F2F2;
    .logo{

        a{
            display: block;
            width: auto;
            @include media-breakpoint-down(md) {
                height: 30px
            }
        }
        img{
            height: 100%;
            object-fit: contain;
            transition: transform .3s ease;
            max-height: 60px;
        }
        &:hover{
            img{ 
                transform: scale(1.2);
            }
           
        }
    }
}

.block-experience, .block-insight{
    a.item{
        picture{
            overflow: hidden;
            &::after{
                position: absolute;
                inset:0;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: rgba(38, 69, 82, .8);
                content: "VIEW  "  url("../assets/next-green.svg");
                opacity: 0;
                transition: opacity .3s ease;
                font-size: 16px;
                font-weight: bold;
            }
            img{
                transition: transform 1s ease;
            }
        }
        &:hover{
            picture::after{
                opacity: 1;
               
            }
            img{
                transform: scale(1.2);
            }
            
        }

    }
}
.block-insight{
    background-color: #F2F2F2;
}

.block-content-bg-image, .block-testimonial{
    picture{
        display: block;
        &::after{
            content:"";
            position: absolute;
            inset:0;
            background-color: #4D4D4D;
            mix-blend-mode: multiply;
            opacity: .57;
            pointer-events: none;
        }
    }
    .link-with-arrow.text-white:hover{
        color:$green;
    }

}

.block-content{
    .link-with-arrow.text-blue{
        border-color:$green;
    }
}

.block-summary-content{
    overflow: hidden;
    .left-content{
        position: relative;

        &::after{
            content:"";
            position: absolute;
            background-color: $blue;
            right:0;
            top:0;
            bottom:0;
            width: 50vw;
            z-index: -1;
            @include media-breakpoint-down(lg) {
                left: -25vw;
                width: 150vw;
            }
        }
    }
}
.block-testimonial{
    background-color: #F6F6F6;
    .logo{
        a{
            display: block;
            height: 30px;
            width: auto;
        }
        img{
            max-height: 30px;
            width: auto;
            object-fit: contain;
                transition: transform .3s ease;
        }
        &:hover{
            img{ 
                transform: scale(1.2);
            }
        }
    } 
}
.block-subscription{
    button[type=submit].text-white.link-with-arrow{
        border-color: $white;
        &::after{
            content: url("../assets/arrow-white.svg")!important;
        }
    }
}
